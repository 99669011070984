import * as React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";

const Main = styled.div`
  min-height: 30vh;
  padding: 1rem;
  text-align: center;
  color: white;
`;

const StyledH1 = styled.h1`
  padding: 1rem;
  font-size: 3rem;
`;

const NotFoundPage = () => {
  return (
    <Layout title="404">
      <SEO pagetitle="404" pagedesc="Page not found" />
      <ContentWrapper>
        <Main>
          <StyledH1>Page not found</StyledH1>
          <p>
            お探しのページはURLが正しく入力されていないか、削除された可能性があります。
          </p>
        </Main>
      </ContentWrapper>
    </Layout>
  );
};

export default NotFoundPage;
